<template>
    <div class="container mt-4 mb-5" style="text-align: left">
        <div class="row mb-5">
            <div class="col-9">
                <h3>FORMULÁRIO GERAL | PREENCHIMENTO UNINOVE</h3>
            </div>
            <div class="col" style="text-align: right">
                <b-button variant="outline-primary" style="margin-left: 10px;" to="/">Voltar</b-button>
            </div>
        </div>

        <div class="row mb-2 mt-4">
            <h2>Dados Gerais da Instituição</h2>
            <hr>
            
        </div>
<!-- 
        <div class="row">
            <b-input-group prepend="Mantenedora" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input id="input-dg_mantenedora.cnpj" v-model="formulario_geral.dg_gerais.dg_mantenedora.nome"></b-form-input>
            </b-input-group>
        </div>
        
        <div class="row">
            <b-form class="mt-2" inline>
                <b-input-group prepend="CNPJ" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="input-dg_mantenedora.cnpj" v-model="formulario_geral.dg_gerais.dg_mantenedora.cnpj"></b-form-input>
                </b-input-group>

                <b-input-group prepend="Código (e-MEC)" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="input-dg_mantenedora.codigo_emec" v-model="formulario_geral.dg_gerais.dg_mantenedora.codigo_emec"></b-form-input>
                </b-input-group>
            </b-form>
        </div>
        
        <div class="row mb-4 mt-2">
            <b-input-group prepend="Natureza Jurídica" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input id="input-dg_mantenedora.njuridica" v-model="formulario_geral.dg_gerais.dg_mantenedora.njuridica"></b-form-input>
            </b-input-group>
        </div>

        <hr class="mt-4 mb-4">

        <div class="row mt-2">
            <b-input-group prepend="Mantida (nome e sigla)" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input id="input-dg_mantida.nome" v-model="formulario_geral.dg_gerais.dg_mantida.nome"></b-form-input>
            </b-input-group>
        </div>

        <div class="row">
            <b-form class="mt-2" inline>
                <b-input-group prepend="Código (e-MEC)" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="input-dg_mantida.codigo_emec" v-model="formulario_geral.dg_gerais.dg_mantida.codigo_emec"></b-form-input>
                </b-input-group>

                <b-input-group prepend="Código (e-MEC) do endereço" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="input-dg_mantida.codigo_emec_endereco" v-model="formulario_geral.dg_gerais.dg_mantida.codigo_emec_endereco"></b-form-input>
                </b-input-group>
            </b-form>
        </div>

        <div class="row mb-2">
            <b-form class="mt-2" inline>
                <b-input-group prepend="Organização Acadêmica" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="input-dg_organizacao_academica" v-model="formulario_geral.dg_gerais.dg_organizacao_academica"></b-form-input>
                </b-input-group>

                <b-input-group prepend="Categoria Administrativa" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="input-dg_categoria_administrativa" v-model="formulario_geral.dg_gerais.dg_categoria_administrativa"></b-form-input>
                </b-input-group>
            </b-form>
        </div>

        <div class="row mt-2">
            <b-input-group prepend="Endereço/CEP" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input id="input-dg_endereco_cep" v-model="formulario_geral.dg_gerais.dg_endereco_cep"></b-form-input>
            </b-input-group>
        </div>

        <div class="row mb-2">
            <b-form class="mt-2" inline>
                <b-input-group prepend="Cidade/UF" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="input-dg_cidade_uf" v-model="formulario_geral.dg_gerais.dg_cidade_uf"></b-form-input>
                </b-input-group>

                <b-input-group prepend="Telefones (DDD e números)" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="input-dg_telefones" v-model="formulario_geral.dg_gerais.dg_telefones"></b-form-input>
                </b-input-group>
            </b-form>
        </div>

        <div class="row mt-2">
            <b-input-group prepend="Página na internet da instituição" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input id="input-dg_pg_internet" v-model="formulario_geral.dg_gerais.dg_pg_internet"></b-form-input>
            </b-input-group>
        </div>

        <hr class="mt-4 mb-4">

        <div class="row mt-2 mt-4">
            <b-input-group prepend="Representante legal" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input id="input-dg_representante_legal.nome" v-model="formulario_geral.dg_gerais.dg_representante_legal.nome"></b-form-input>
            </b-input-group>
        </div>

        <div class="row mb-2">
            <b-form class="mt-2" inline>
                <b-input-group prepend="Cargo" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="input-dg_representante_legal.cargo" v-model="formulario_geral.dg_gerais.dg_representante_legal.cargo"></b-form-input>
                </b-input-group>

                <b-input-group prepend="E-mail" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="input-dg_representante_legal.email" v-model="formulario_geral.dg_gerais.dg_representante_legal.email"></b-form-input>
                </b-input-group>
            </b-form>
        </div>

        <div class="row mb-2">
            <b-form class="mt-2" inline>
                <b-input-group prepend="CPF" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="input-dg_representante_legal.cpf" v-model="formulario_geral.dg_gerais.dg_representante_legal.cpf"></b-form-input>
                </b-input-group>

                <b-input-group prepend="Telefone (DDD e número)" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="input-dg_representante_legal.telefone" v-model="formulario_geral.dg_gerais.dg_representante_legal.telefone"></b-form-input>
                </b-input-group>
            </b-form>
        </div>

        <hr class="mt-4 mb-4">

        <div class="row mt-2">
            <b-input-group prepend="Recenseador Institucional" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input id="input-dg_recenseador_institucional.nome" v-model="formulario_geral.dg_gerais.dg_recenseador_institucional.nome"></b-form-input>
            </b-input-group>
        </div>

        <div class="row mb-2">
            <b-form class="mt-2" inline>
                <b-input-group prepend="Telefone (DDD e número)" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="input-dg_recenseador_institucional.telefone" v-model="formulario_geral.dg_gerais.dg_recenseador_institucional.telefone"></b-form-input>
                </b-input-group>

                <b-input-group prepend="E-mail" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="input-dg_recenseador_institucional.email" v-model="formulario_geral.dg_gerais.dg_recenseador_institucional.email"></b-form-input>
                </b-input-group>
            </b-form>
        </div>

         <hr class="mt-4 mb-4">

        <div class="row mt-2 mt-4">
            <b-input-group prepend="Coordenador Pedagógico do Curso" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input id="input-dg_coordenador_curso.nome" v-model="formulario_geral.dg_gerais.dg_coordenador_curso.nome"></b-form-input>
            </b-input-group>
        </div>

        <div class="row mb-2">
            <b-form class="mt-2" inline>
                <b-input-group prepend="Telefone (DDD e número)" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="input-dg_coordenador_curso.telefone" v-model="formulario_geral.dg_gerais.dg_coordenador_curso.telefone"></b-form-input>
                </b-input-group>

                <b-input-group prepend="E-mail" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="input-dg_coordenador_curso.email" v-model="formulario_geral.dg_gerais.dg_coordenador_curso.email"></b-form-input>
                </b-input-group>
            </b-form>
        </div> -->


        <itemInput forms="uninove_geral" code="item_0_0" titulo="Preambulo" v-model="formulario_geral.item_0_0" @valorEmitido="log"></itemInput>

        <itemInput forms="uninove_geral" code="item_0_0_1" titulo="Biblioteca" v-model="formulario_geral.item_0_0_1" @valorEmitido="log"></itemInput>

        
        <hr>

        <div class="row mb-2 mt-4">
            <h2>1. Apresentação e justificativa</h2>
            <hr>
        </div>

        <!-- 1.1 Apresentação da Instituição. -->
        <div class="row mb-5">
            <itemInput forms="uninove_geral" code="item_1_1" titulo="1.1 Apresentação da Instituição" v-model="formulario_geral.item_1_1" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="uninove_geral" code="item_1_1" titulo="1.1 Apresentação da Instituição" v-model="formulario_geral.item_1_1" @valorEmitido="log"></richTextInput> -->
        </div>

        <!-- 1.2 Apresentação da unidade de ensino ofertante (Código e-MEC do endereço). -->
        <!-- <div class="row mb-5">
            <itemInput forms="uninove_geral" code="item_1_2"  titulo="*************1.2 Apresentação da unidade de ensino ofertante (Código e-MEC do endereço)" v-model="formulario_geral.item_1_2" @valorEmitido="log"></itemInput>
        </div> -->

        <div class="row mb-2 mt-3">
            <h2>2. Formas de acesso</h2>
            <hr>
        </div>

        <!-- 2.1. Pré-requisitos para acesso ao curso -->
        <div class="row mb-2 mt-2">
            <itemInput forms="uninove_geral" code="item_2_1" titulo="2.1. Pré-requisitos para acesso ao curso" v-model="formulario_geral.item_2_1" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="uninove_geral" code="item_2_1" titulo="2.1. Pré-requisitos para acesso ao curso" v-model="formulario_geral.item_2_1" @valorEmitido="log"></richTextInput> -->
        </div>

        <!-- 2.2 Formas de acesso ao curso -->
        <div class="row mb-2 mt-4">
            <itemInput forms="uninove_geral" code="item_2_2"  titulo="2.2 Formas de acesso ao curso" v-model="formulario_geral.item_2_2" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="uninove_geral" code="item_2_2" titulo="2.2 Formas de acesso ao curso" v-model="formulario_geral.item_2_2" @valorEmitido="log"></richTextInput> -->
        </div>

        <div class="row mb-5 mt-3">
            <h2>4. Organização Curricular</h2>
            <hr>
        </div>

        <!-- 4.4. Critérios e Mecanismos de Aproveitamento de Conhecimentos e Experiências Anteriores -->
        <div class="row mb-2 mt-4">
            <itemInput forms="uninove_geral" code="item_4_4"  titulo="4.4. Critérios e Mecanismos de Aproveitamento de Conhecimentos e Experiências Anteriores" v-model="formulario_geral.item_4_4" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="uninove_geral" code="item_4_4" titulo="4.4. Critérios e Mecanismos de Aproveitamento de Conhecimentos e Experiências Anteriores" v-model="formulario_geral.item_4_4" @valorEmitido="log"></richTextInput> -->
        </div>

        <!-- 4.5. Procedimentos, Mecanismos e Gestão da Avaliação do Processo Ensino-Aprendizagem -->
        <div class="row mb-2 mt-4">
            <itemInput forms="uninove_geral" code="item_4_5"  titulo="4.5. Procedimentos, Mecanismos e Gestão da Avaliação do Processo Ensino-Aprendizagem" v-model="formulario_geral.item_4_5" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="uninove_geral" code="item_4_5" titulo="4.5. Procedimentos, Mecanismos e Gestão da Avaliação do Processo Ensino-Aprendizagem" v-model="formulario_geral.item_4_5" @valorEmitido="log"></richTextInput> -->
        </div>

        <!-- 4.6.1 Estratégias de acompanhamento e superação de dificuldades -->
        <div class="row mb-2 mt-4">
            <itemInput forms="uninove_geral" code="item_4_6_1"  titulo="4.6.1 Estratégias de acompanhamento e superação de dificuldades" v-model="formulario_geral.item_4_6_1" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="uninove_geral" code="item_4_6_1" titulo="4.6.1 Estratégias de acompanhamento e superação de dificuldades" v-model="formulario_geral.item_4_6_1" @valorEmitido="log"></richTextInput> -->
        </div>

        <!-- 4.6.2 Estratégias de recuperação -->
        <div class="row mb-2 mt-4">
            <itemInput forms="uninove_geral" code="item_4_6_2"  titulo="4.6.2 Estratégias de recuperação" v-model="formulario_geral.item_4_6_2" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="uninove_geral" code="item_4_6_2" titulo="4.6.2 Estratégias de recuperação" v-model="formulario_geral.item_4_6_2" @valorEmitido="log"></richTextInput> -->
        </div>

        <!-- 4.6.3 Promoção/Pendência -->
        <div class="row mb-2 mt-4">
            <itemInput forms="uninove_geral" code="item_4_6_3"  titulo="4.6.3 Promoção/Pendência" v-model="formulario_geral.item_4_6_3" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="uninove_geral" code="item_4_6_3" titulo="4.6.3 Promoção/Pendência" v-model="formulario_geral.item_4_6_3" @valorEmitido="log"></richTextInput> -->
        </div>

        <!-- 4.7. Regras de Trancamento -->
        <div class="row mb-2 mt-4">
            <itemInput forms="uninove_geral" code="item_4_7"  titulo="4.7. Regras de Trancamento" v-model="formulario_geral.item_4_7" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="uninove_geral" code="item_4_7" titulo="4.7. Regras de Trancamento" v-model="formulario_geral.item_4_7" @valorEmitido="log"></richTextInput> -->
        </div>

        <!-- 4.8 Descrição do funcionamento dos serviços de apoio psicopedagógico e atendimento educacional especializado -->
        <div class="row mb-2 mt-4">
            <itemInput forms="uninove_geral" code="item_4_8"  titulo="4.8 Descrição do funcionamento dos serviços de apoio psicopedagógico e atendimento educacional especializado" v-model="formulario_geral.item_4_8" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="uninove_geral" code="item_4_8" titulo="4.8 Descrição do funcionamento dos serviços de apoio psicopedagógico e atendimento educacional especializado" v-model="formulario_geral.item_4_8" @valorEmitido="log"></richTextInput> -->
        </div>

        <div class="row mb-5 mt-3">
            <h2>7. Diplomas e Certificados Expedidos aos que concluíram o Curso Técnico ou certificação intermediária, conforme Diretrizes curriculares. </h2>
            <hr>
        </div>

        <!-- 7.1 Descrição das informações do diploma a ser emitido -->
        <div class="row mb-2 mt-4">
            <itemInput forms="uninove_geral" code="item_7_1"  titulo="7.1 Descrição das informações do diploma a ser emitido" v-model="formulario_geral.item_7_1" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="uninove_geral" code="item_7_1" titulo="7.1 Descrição das informações do diploma a ser emitido" v-model="formulario_geral.item_7_1" @valorEmitido="log"></richTextInput> -->
        </div>

        <!-- 7.2 Descrição das informações dos certificados de qualificação profissional -->
        <div class="row mb-2 mt-4">
            <itemInput forms="uninove_geral" code="item_7_2"  titulo="7.2 Descrição das informações dos certificados de qualificação profissional" v-model="formulario_geral.item_7_2" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="uninove_geral" code="item_7_2" titulo="7.2 Descrição das informações dos certificados de qualificação profissional" v-model="formulario_geral.item_7_2" @valorEmitido="log"></richTextInput> -->
        </div>

        <!-- 8. Descrição do Ambiente Virtual de Aprendizagem -->
        <div class="row mb-5 mt-3">
            <h2>8. Descrição do Ambiente Virtual de Aprendizagem</h2>
            <hr>
            <itemInput forms="uninove_geral" code="item_8"  titulo="" v-model="formulario_geral.item_8" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="uninove_geral" code="item_8" titulo="" v-model="formulario_geral.item_8" @valorEmitido="log"></richTextInput> -->
        </div>

        <div class="row mb-5 mt-3">
            <h2>9. Anexos</h2>
            <hr>
        </div>

        <!-- 9.1 Enxerto do Regimento Interno -->
        <div class="row mb-2 mt-4">
            <itemInput forms="uninove_geral" code="item_9_1"  titulo="9.1 Enxerto do Regimento Interno" v-model="formulario_geral.item_9_1" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="uninove_geral" code="item_9_1" titulo="9.1 Enxerto do Regimento Interno" v-model="formulario_geral.item_9_1" @valorEmitido="log"></richTextInput> -->
        </div>

        
        

    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';
import itemInput from '@/views/teste_nadir/components/itemInput.vue'
// import richTextInput from '@/views/teste_nadir/components/richTextInput.vue'

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    components: { 
        itemInput,
        // richTextInput
    },
    data() {
        return {
            options_campus: ['Vila Maria', 'Vergueiro', 'Vila Prudente', 'Memorial', 'Bauru'],
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }], 
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["blockquote"]
            ],
            value_campus: {
                vila_maria: '',
                vergueiro: '',
                vila_prudente: ''
            },
            selected_campus: '',
            formulario_geral: {
                // Dados Gerais da Instituição
                dg_gerais: {
                    dg_mantenedora: {
                        nome: '',
                        cnpj: '',
                        codigo_emec: '',
                        njuridica: '',
                    },
                    dg_mantida: {
                        nome: '',
                        codigo_emec: '',
                        codigo_emec_endereco: ''
                    },
                    dg_organizacao_academica: '',
                    dg_categoria_administrativa: '',
                    dg_endereco_cep: '',
                    dg_cidade_uf: '',
                    dg_telefones: '',
                    dg_pg_internet: '',
                    dg_representante_legal: {
                        nome: '',
                        cargo: '',
                        email: '',
                        telefone: '',
                        cpf: '',
                    },
                    dg_recenseador_institucional: {
                        nome: '',
                        email: '',
                        telefone: '',
                    },
                    dg_coordenador_curso: {
                        nome: '',
                        email: '',
                        telefone: '',
                    },
                },
                // Dados Gerais do Curso Técnico de Nível Médio
                dg_gerais_curso_tecnico: {
                    denominacao: 'Curso Técnico em Serviços Jurídicos',
                    carga_horaria_curso: '800 horas',
                    carga_horaria_estagio: 'Não se aplica',
                    modalidade_oferta: '<presencial ou a distância>',
                    forma_oferta: '<concomitante ou subsequente>',
                },
                // Certificações intermediárias (se houver): <Repetir para cada uma>
                certificacoes_intermediarias: [
                    {
                        denominacao: 'Qualificação Profissional em ...1',
                        carga_horaria: '<horas-relógio>1'
                    },
                    {
                        denominacao: 'Qualificação Profissional em ...2',
                        carga_horaria: '<horas-relógio>2'
                    }
                ],
                // Organização da Oferta
                organizacao_oferta: {
                    periodicidade_matriculas: '<anual, semestral ou modular>',
                    turnos_funcionamento: '<matutino, vespertino e/ou noturno>',
                    prazo_min_integralizacao: '<em anos>',
                    prazo_max_integralizacao: '<em anos>',
                    num_turmas_matutino: '',
                    num_turmas_vespertino: '',
                    num_turmas_noturno: '',
                    vagas_por_turmas_matutino: '',
                    vagas_por_turmas_vespertino: '',
                    vagas_por_turmas_noturno: '',
                    vagas_totais_anuais_solicitadas: ''
                },
                // Proposta Pedagógica do Curso
                item_1: '',
                item_1_1: '',
                item_1_2: '',
                item_1_3: '',
                item_1_4: '',
                item_1_5: '',
                item_1_5_1: '',
                item_1_5_2: '',
                // 2
                item_2: '',
                item_2_1: '',
                item_2_2: '',
                // 3
                item_3: '',
                item_3_1: '',
                // 4
                item_4: '',
                item_4_1: '',
                item_4_2: '',
                item_4_2_1: [],
                item_4_3: '',
                item_4_4: '',
                item_4_5: '',
                item_4_6: '',
                item_4_6_1: '',
                item_4_6_2: '',
                item_4_6_3: '',
                item_4_7: '',
                item_4_8: '',
                item_4_9: '', // ??????
                // 5
                item_5: '',
                item_5_1: '',
                item_5_2: [ // 5.2 Relação de laboratórios (Repetir para cada um).
                    {
                        nome_lab: 'lab_1',
                        finalidade: 'f1',
                        quantidade: 'X1',
                        capacidade: ''
                    },
                    {
                        nome_lab: 'lab_2',
                        finalidade: 'f2',
                        quantidade: 'X2',
                        capacidade: ''
                    }
                ],
                item_5_3: [ // 5.3 Materiais, equipamentos, ferramentas e outros insumos para atividades práticas:
                    {
                        nome_item: '',
                        quantidade: '',
                        finalidade: '',
                        capacidade: ''
                    }
                ],
                item_5_4: [ // 5.4 Outras instalações (Repetir para cada uma).
                    {
                        nome: '',
                        quantidade: '',
                        finalidade: '',
                        capacidade: ''
                    }
                ],
                item_5_5: [ // 5.5 Recursos de acessibilidade (repetir para cada uma).
                    {
                        nome: '',
                        quantidade: '',
                        finalidade: '',
                        capacidade: ''
                    }
                ],
                item_5_6: '',
                item_5_7: { // 5.7 Acervo bibliográfico do curso.
                    fields: 
                    [
                        { key: 'titulo', label: 'Título' },
                        { key: 'acesso', label: 'Forma de acesso do aluno' },
                    ],
                    items: 
                    [
                        { titulo: 'AGUIAR, Antonio Carlos. Advocacia trabalhista. 2. ed. São Paulo: Saraiva Educação, 2018. E-book. Disponível em: https://integrada.minhabiblioteca.com.br/reader/books/9788547229542/pageid/0. Acesso em: 14 jun. 2022.', acesso: 'Virtual' },
                        { titulo: 'ALCÂNTARA, Silvano Alves. Direito administrativo para serviços jurídicos. Curitiba: Contentus, 2020. E-book. Disponível em: https://plataforma.bvirtual.com.br/Acervo/Publicacao/184704. Acesso em: 14 jun. 2022.', acesso: 'Virtual' },
                        { titulo: 'ALCÂNTRA, André Feitosa; JESUS, Fábio Rodrigues de; CARDOSO, José Nildo Alves. Serviços jurídicos: práticas em departamentos jurídicos, de recursos humanos e de contabilidade. São Paulo: Érica, 2019. E-book. Disponível em: https://integrada.minhabiblioteca.com.br/reader/books/9788536530499/pageid/0. Acesso em: 14 jun. 2022.', acesso: 'Virtual'},
                        { titulo: 'ALMEIDA, Guilherme Assis de; CHRISTMANM, Martha Ochsenhofer. Ética e Direito: uma perspectiva integrada. 3. ed. São Paulo: Atlas, 2009. E-book. Disponível em: https://integrada.minhabiblioteca.com.br/#/books/9788522467150/cfi/0!/4/2@100:0.00. Acesso em: 14 jun. 2022.', acesso: 'Virtual'}
                    ]
                },
                // 6
                item_6: '',
                item_6_1: [ // Corpo Docente (Repetir para cada um).
                    {
                        nome: '',
                        formacao_principal: '',
                        formacao_adicionais: [],
                        unidades_curriculares: [],
                        regime_trabalho: '',
                        temp_atividade_instituicao: '',
                        temp_experiencia_prof: '',
                    }
                ],
                item_6_2: [ // Corpo Tutorial (exclusivo para curso na modalidade a distância). 
                    {

                    }
                ],
                item_6_3: {  // Coordenador Pedagógico do Curso.
                        nome: '',
                        formacao_principal: '',
                        formacao_adicionais: [],
                        regime_trabalho: '',
                        temp_atividade_instituicao: '',
                        temp_experiencia_prof: '',
                        formas_atendimento_alunos: [],
                        tempo_dedicado_alunos: ''
                },
                item_6_4: [ // Pessoal Técnico Administrativo. <Repetir para cada um>
                    {
                        nome: '',
                        formacao_principal: '',
                        formacao_adicionais: [],
                        regime_trabalho: '',
                        temp_atividade_instituicao: '',
                        temp_experiencia_prof: '',
                    }
                ],
                // 7
                item_7: '',
                item_7_1: '',
                item_7_2: '',
                // 8
                item_8: '<h1>Some initial content</h1>',
                // 9
                item_9: '',
                item_9_1: '',
                item_9_2: '',
            }
        }
    },
    methods:{
        log(value){
            console.log('Item atualizado', value)
            // Após o elemento concluir a inserção de dados no firebase, atualiza os dados
            this.atualizarDados()
        },

        atualizarDados(){
            db.collection("/nadir").doc('forms_geral')
            .get()
            .then((doc) => {
                this.formulario_geral = doc.data().documento_completo
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
        },

        cadastrar(){
            db.collection("/nadir").doc('forms_geral')
            .set({formulario_geral: this.documento_completo})
            .then(() => {
                alert('Alterações salvas com sucesso')
                console.log("Document successfully updated!");
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        },

        addItem(item){
            item.push({id: item.length , valor: ''})
        }

    },
    mounted() {
        db.collection("/nadir").doc('forms_geral')
        .get()
        .then((doc) => {
            this.formulario_geral = doc.data().documento_completo
            console.log(this.formulario_geral)
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });
    }
}
</script>

<style scoped>

.quillWrapper{
    height: fit-content;
}
   

</style>